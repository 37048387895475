<template>
    <div class="jh-container" style="width: 1200px;">
        <div class="jh-ui-header">
            <h1>광고인 상세</h1>
            <div class="is-right">
            </div>
        </div>
            <div class="jh-form-wrap is-mt-15" style="margin-top: 80px;" >
                <div class="jh-cols">
                    <table class="jh-tbl-detail" style="width: 33%;">
                    <colgroup>
                        <col width="100px">
                        <col>
                    </colgroup>
                    <tbody>
                        <th style="width: 33%;"><label>소속</label></th>
                        <td height="50px" >
                            <v-text-field class="jh-form is-xl" height="50px" v-model="CEN_TYPE_CD" disabled></v-text-field>
                        </td>
                    </tbody>
                    </table>
                    <table class="jh-tbl-detail" style="width: 33%;">
                    <colgroup>
                        <col width="100px">
                        <col>
                    </colgroup>
                    <tbody>
                        <th><label>광고인</label></th>
                        <td>
                            <v-autocomplete
                            class="jh-form is-xl"
                            v-model="USER_ID"
                            :items="USER_LIST"
                            no-data-text="일치하는 데이터가 없습니다."
                            item-text="USER_INFO"
                            item-value="USER_ID"
                            placeholder="선택"
                            :disabled="CHK_BL == true"
                            >
                            </v-autocomplete>
                        </td>     
                    </tbody>
                    </table>
                    <table class="jh-tbl-detail" style="width: 33%;">
                    <colgroup>
                        <col width="100px">
                        <col>
                    </colgroup>
                    <tbody>
                        <th><label>상담원</label></th>
                        <td>
                            <v-autocomplete
                            class="jh-form is-xl"
                            v-model="COUNSELOR_ID"
                            :items="COUNSELOR_LIST"
                            no-data-text="일치하는 데이터가 없습니다."
                            item-text="USER_INFO"
                            item-value="USER_ID"
                            placeholder="선택"
                            :disabled="CHK_BL == true"
                            >
                            </v-autocomplete>
                        </td>     
                    </tbody>
                    </table>                    
                </div>
            </div>


            <div class="jh-form-wrap is-mt-15" >
                <div class="jh-cols">
                    <table class="jh-tbl-detail" style="width: 33%;">
                    <colgroup>
                        <col width="100px">
                        <col>
                    </colgroup>
                    <tbody>
                        <th><label>피보험자</label></th>
                        <td>
                            <v-text-field class="jh-form is-xl" height="50px" v-model="INSURED_PERSON"></v-text-field>
                        </td>
                    </tbody>
                    </table>
                    <table class="jh-tbl-detail" style="width: 33%;">
                    <colgroup>
                        <col width="100px">
                        <col>
                    </colgroup>
                    <tbody>
                        <th><label>주민번호</label></th>
                        <td>
                            <v-text-field class="jh-form is-xl" height="50px" v-model="INSURED_SSN"></v-text-field>
                        </td>
                    </tbody>
                    </table>
                    <table class="jh-tbl-detail" style="width: 33%;">
                    <colgroup>
                        <col width="100px">
                        <col width="300px">
                        <col>
                    </colgroup>
                    <tbody>
                        <th><label>연락처</label></th>
                        <td>
                            <v-text-field class="jh-form is-xl" height="50px" v-model="INSURED_HP"></v-text-field>
                        </td>
                        <td><v-checkbox class="jh-check" width="10px" v-model="fixedFlag"  /></td>
                    </tbody>
                    </table>                                        
                </div>
                <div class="jh-cols" style="margin-top: 4px;">
                    <table class="jh-tbl-detail" style="width: 33%;">
                    <colgroup>
                        <col width="100px">
                        <col>
                    </colgroup>
                    <tbody>
                        <th><label>계약자</label></th>
                        <td>
                            <v-text-field class="jh-form is-xl" height="50px" v-model="CONTRACTOR"></v-text-field>
                        </td>
                    </tbody>
                    </table>
                    <table class="jh-tbl-detail" style="width: 33%;">
                    <colgroup>
                        <col width="100px">
                        <col>
                    </colgroup>
                    <tbody>
                        <th><label>주민번호</label></th>
                        <td>
                            <v-text-field class="jh-form is-xl" height="50px" v-model="CONTRACT_SSN"></v-text-field>
                        </td>
                    </tbody>
                    </table>
                    <table class="jh-tbl-detail" style="width: 33%;">
                    <colgroup>
                        <col width="100px">
                        <col>
                    </colgroup>
                    <tbody>
                        <th><label>연락처</label></th>
                        <td>
                            <v-text-field class="jh-form is-xl" height="50px" v-model="CONTRACT_HP"></v-text-field>
                        </td>
                    </tbody>
                    </table>                                        
                </div>                
            </div>   




            <div class="jh-form-wrap is-mt-15" >
                <div class="jh-cols" >
                    <table class="jh-tbl-detail" style="width: 33%;">
                    <colgroup>
                        <col width="100px">
                        <col>
                    </colgroup>
                    <tbody>
                        <th><label>보험사</label></th>
                        <td>
                            <v-autocomplete
                            class="jh-form is-xl"
                            v-model="INSURE_CO"
                            :items="INSURE_CO_LIST"
                            no-data-text="일치하는 데이터가 없습니다."
                            item-text="NM"
                            item-value="CD"
                            placeholder="선택"
                            :disabled="CHK_BL == true"
                            >
                            </v-autocomplete>
                        </td>
                    </tbody>
                    </table>
                    <table class="jh-tbl-detail" style="width: 33%;">
                    <colgroup>
                        <col width="100px">
                        <col>
                    </colgroup>
                    <tbody>
                        <th><label>가입방식</label></th>
                        <td>
                            <v-autocomplete
                            class="jh-form is-xl"
                            v-model="CONTRACT_TYP"
                            :items="CONTRACT_TYP_LIST"
                            no-data-text="일치하는 데이터가 없습니다."
                            item-text="NM"
                            item-value="CD"
                            placeholder="선택"
                            :disabled="CHK_BL == true"
                            @change="calculateAd()"
                            >
                            </v-autocomplete>
                        </td>
                    </tbody>
                    </table>                      
                    <table class="jh-tbl-detail" style="width: 33%;">
                    <colgroup>
                        <col width="100px">
                        <col>
                    </colgroup>
                    <tbody>
                        <th><label>보험종목</label></th>
                        <td>
                            <v-autocomplete
                            class="jh-form is-xl"
                            v-model="INSURE_TYP"
                            :items="INSURE_TYP_LIST"
                            no-data-text="일치하는 데이터가 없습니다."
                            item-text="NM"
                            item-value="CD"
                            placeholder="선택"
                            :disabled="CHK_BL == true"
                            @change="calculateAd()"
                            >
                            </v-autocomplete>
                        </td>
                    </tbody>
                    </table>        
                                                  
                </div>
                <div class="jh-cols" style="margin-top: 4px;">
                    <table class="jh-tbl-detail" style="width: 33%;">
                    <colgroup>
                        <col width="100px">
                        <col>
                    </colgroup>
                    <tbody>
                        <th><label>계약번호</label></th>
                        <td>
                            <v-text-field class="jh-form is-xl" height="50px" v-model="CONTRACT_NUM"></v-text-field>
                        </td>
                    </tbody>
                    </table> 

                    <table class="jh-tbl-detail" style="width: 33%;">
                    <colgroup>
                        <col width="100px">
                        <col>
                    </colgroup>
                    <tbody>
                        <th><label>계약상태</label></th>
                        <td>
                            <v-autocomplete
                            class="jh-form is-xl"
                            v-model="CONTRACT_STATUS"
                            :items="CONTRACT_STATUS_LIST"
                            no-data-text="일치하는 데이터가 없습니다."
                            item-text="NM"
                            item-value="CD"
                            placeholder="선택"
                            :disabled="CHK_BL == true"
                            >
                            </v-autocomplete>
                        </td>
                    </tbody>
                    </table> 

                    <table class="jh-tbl-detail" style="width: 33%;">
                    <colgroup>
                        <col width="100px">
                        <col>
                    </colgroup>
                    <tbody>
                        <th><label>갱신상태</label></th>
                        <td>
                            <v-autocomplete
                            class="jh-form is-xl"
                            v-model="RENEWAL_STATUS"
                            :items="RENEWAL_STATUS_LIST"
                            no-data-text="일치하는 데이터가 없습니다."
                            item-text="NM"
                            item-value="CD"
                            placeholder="선택"
                            :disabled="CHK_BL == true"
                            >
                            </v-autocomplete>
                        </td>
                    </tbody>
                    </table>                     
                    <table class="jh-tbl-detail" style="width: 33%;">
                    <colgroup>
                        <col width="100px">
                        <col>
                    </colgroup>
                    <tbody>
                        <th><label>실적미인정</label></th>
                        <td>
                            <v-autocomplete
                            class="jh-form is-xl"
                            v-model="PERFORMENCE_TYP"
                            :items="PERFORMENCE_TYP_LIST"
                            no-data-text="일치하는 데이터가 없습니다."
                            item-text="NM"
                            item-value="CD"
                            placeholder="선택"
                            :disabled="CHK_BL == true"
                            @change="calculateAd()"
                            >
                            </v-autocomplete>
                        </td>
                    </tbody>
                    </table>                                        
                </div>                
                <div class="jh-cols" style="margin-top: 4px;">
                    <table class="jh-tbl-detail" style="width: 33%;">
                    <colgroup>
                        <col width="100px">
                        <col>
                    </colgroup>
                    <tbody>
                        <th><label>은행명</label></th>
                        <td>
                            <v-text-field class="jh-form is-xl" height="50px" disabled v-model="USER_BK"></v-text-field>
                        </td>
                    </tbody>
                    </table>
                    <table class="jh-tbl-detail" style="width: 33%;">
                    <colgroup>
                        <col width="100px">
                        <col>
                    </colgroup>
                    <tbody>
                        <th><label>계좌번호</label></th>
                        <td>
                            <v-text-field class="jh-form is-xl" height="50px" disabled v-model="USER_ACT"></v-text-field>
                        </td>
                    </tbody>
                    </table>                                   
                </div>   
                <div class="jh-cols" style="margin-top: 4px;">
                    <table class="jh-tbl-detail" style="width: 33%;">
                    <colgroup>
                        <col width="100px">
                        <col>
                    </colgroup>
                    <tbody>
                        <th><label>보험료</label></th>
                        <td>
                            <v-text-field class="jh-form is-xl" height="50px" v-model="INSURE_FEE" @change="calculateAd()"></v-text-field>
                        </td>
                    </tbody>
                    </table>
                    <table class="jh-tbl-detail" style="width: 33%;">
                    <colgroup>
                        <col width="100px">
                        <col>
                    </colgroup>
                    <tbody>
                        <th><label>광고수수료</label></th>
                        <td>
                            <v-text-field class="jh-form is-xl" height="50px"  v-model="AD_FEE"></v-text-field>
                        </td>
                    </tbody>
                    </table>                                     
                </div>    
                <div class="jh-cols" style="padding-top:11px;padding-bottom:11px;padding-left:10px;padding-right:10px;">
                    <hr>
                </div>
                <div class="jh-cols">
                    <table class="jh-tbl-detail" style="width: 33%;">
                    <colgroup>
                        <col width="100px">
                        <col>
                    </colgroup>
                    <tbody>
                        <th><label>가입일</label></th>
                        <td>                        
                            <div class="jh-cols">                        
                            <v-menu v-model="menuInitDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="init_dates[0]" v-bind="attrs" v-on="on" class="jh-form is-xl" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuInitDate = false"
                                oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                                </template>
                                <v-date-picker class="jh-calendar" v-model="init_dates[0]" @input="menuInitDate = false" no-title locale="ko-KR" :day-format="mixin_getDate" @change="changeDate('start')"></v-date-picker>
                            </v-menu>
                            </div>
                        </td>
                    </tbody>
                    </table>                                     
                </div>    
                <div class="jh-cols" style="margin-top: 4px;">
                    <table class="jh-tbl-detail" style="width: 33%;">
                    <colgroup>
                        <col width="100px">
                        <col>
                    </colgroup>
                    <tbody>
                        <th><label>계약기간</label></th>
                        <td>                        
                            <div class="jh-cols">                        
                            <v-menu v-model="menuStartDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="dates[0]" v-bind="attrs" v-on="on" class="jh-form is-xl" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuStartDate = false"
                                oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                                </template>
                                <v-date-picker class="jh-calendar" v-model="dates[0]" @input="menuStartDate = false" no-title locale="ko-KR" :day-format="mixin_getDate" @change="changeDate('start')"></v-date-picker>
                            </v-menu>
                            <span class="jh-unit">~</span>                       
                            <v-menu v-model="menuEndDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="dates[1]" v-bind="attrs" v-on="on" class="jh-form is-xl" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuEndDate = false"
                                oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                                </template>
                                <v-date-picker class="jh-calendar" v-model="dates[1]" @input="menuEndDate = false" no-title locale="ko-KR" :min="dates[0]" :day-format="mixin_getDate" @change="changeDate('end')"></v-date-picker>
                            </v-menu>
                            </div>
                        </td>
                    </tbody>
                    </table>                                     
                </div>           
                <div class="jh-cols" style="margin-top: 4px;">
                    <table class="jh-tbl-detail" style="width: 33%;">
                    <colgroup>
                        <col width="100px">
                        <col>
                    </colgroup>
                    <tbody>
                        <th><label>차량번호</label></th>
                        <td>
                            <v-text-field class="jh-form is-xl" height="50px" v-model="CAR_NUM"></v-text-field>
                        </td>
                    </tbody>
                    </table>                     
                    <table class="jh-tbl-detail" style="width: 33%;">
                    <colgroup>
                        <col width="100px">
                        <col>
                    </colgroup>
                    <tbody>
                        <th><label>차량명</label></th>
                        <td>
                            <v-text-field class="jh-form is-xl" height="50px" v-model="CAR_NM"></v-text-field>
                        </td>
                    </tbody>
                    </table>
                    <table class="jh-tbl-detail" style="width: 33%;">
                    <colgroup>
                        <col width="100px">
                        <col>
                    </colgroup>
                    <tbody>
                        <th><label>연식</label></th>
                        <td>
                            <v-text-field class="jh-form is-xl" height="50px" v-model="CAR_YEAR"></v-text-field>
                        </td>
                    </tbody>
                    </table>                                                           
                </div>    
                <div class="jh-cols" style="margin-top: 4px;">
                    <table class="jh-tbl-detail" style="width: 33%;">
                    <colgroup>
                        <col width="100px">
                        <col>
                    </colgroup>
                    <tbody>
                        <th><label>특이사항</label></th>
                        <td>
                            <v-textarea class="jh-form is-xl" height="150px" v-model="SIGNIFICANT"></v-textarea>
                        </td>
                    </tbody>
                    </table>
                    <table class="jh-tbl-detail" style="width: 33%;">
                    <colgroup>
                        <col width="100px">
                        <col>
                    </colgroup>
                    <tbody>
                        <th><label>메모</label></th>
                        <td>
                            <v-textarea class="jh-form is-xl" height="150px" v-model="MEMO"></v-textarea>
                        </td>
                    </tbody>
                    </table>                                     
                </div>                                                         
            </div> 

            <div class="jh-form-wrap is-mt-15" style="margin-top: 80px;" >
                <div class="jh-dialog-body">
                    <div class="jh-ui-header is-mt-10">
                        <h2>첨부파일</h2>
                        <div class="is-right">
                            <v-btn class="jh-btn is-border-blue" @click="clickAddFiles" :disabled="CHK_BL == true" >파일등록</v-btn>
                            <v-btn class="jh-btn is-del" @click="clickDelFiles" :disabled="CHK_BL == true" >파일삭제</v-btn>
                        </div>
                    </div>
                        <v-data-table
                            class="jh-grid has-control"
                            height="116"
                            :headers="gridDataHeaders"
                            :items="gridDataText"
                            v-model="gridListSelected"
                            item-key="index"
                            fixed-header
                            hide-default-footer
                            calculate-widths
                            show-select
                            :itemsPerPage="-1"
                        >
                        <template v-slot:item.DOWNLOAD = {item}>
                        <div>
                            <v-btn  class="jh-btn is-sm is-fill" @click="fileDownload(item)">다운로드</v-btn>
                        </div>
                        </template>
                        </v-data-table>
                </div>

                        <!-- 파일 업로드 모달창 -->
                        <v-dialog persistent v-model="dialogVueDropzone" v-if="dialogVueDropzone">
                        <dialog-VueDropzone
                            headerTitle="첨부파일 등록"
                            :ASP_NEWCUST_KEY = this.dropZoneParam.ASP_NEWCUST_KEY
                            :fileGroupKey    = this.dropZoneParam.fileGroupKey
                            :pathType        = this.dropZoneParam.pathType
                            :busiType        = this.dropZoneParam.busiType
                            :acceptedFiles   = this.dropZoneParam.acceptedFiles
                            :userOptions     = this.dropZoneParam.userOptions
                            @hide="hideDialog('VueDropzone')"
                            @submit="uploadFileResponse"
                        >
                        </dialog-VueDropzone>
                        </v-dialog>
            </div>   
            <div class="is-right" style="display: flex; justify-content: center; margin-top:10px">
                <v-btn class="jh-btn is-md is-main" :disabled="CHK_BL == true" @click="newSave">저장</v-btn>
            </div>            
    </div>
</template>

<script>
import { mixin } from "@/mixin/mixin.js";
import dataTables from "@/components/DataTables";
  import DialogVueDropzone from "@/components/VueDropzone";
  import vue2Dropzone from "vue2-dropzone";

import iconv from "iconv-lite";
import he from "he";

export default {
    name: "MENU_E999993", //name은 'MENU_' + 파일명 조합
    mixins: [mixin],
    components: {
      vueDropzone: vue2Dropzone,

        dataTables, DialogVueDropzone
    },
    props: {},
    data() {
        return {

      FILE : "",
      FILE_GROUP_KEY : "",
      fixedFlag : false,

      dialogVueDropzone: false,
            CONTRACT_NUM:"",
            MODE:"",
            menuInitDate: false,
            menuStartDate: false,
            menuEndDate: false,            
            dates: [
                new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10),
                new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10),
            ],

        init_dates: [
                new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10),
        ],            
        USER_LIST:[],
        COUNSELOR_LIST:[],
        USER_ID:"",
        COUNSELOR_ID:"",
        INSURED_PERSON:"",
        INSURED_SSN:"",
        INSURED_HP:"",
        CONTRACTOR:"",
        CONTRACT_SSN:"",
        CONTRACT_HP:"",
        CONTRACT_TYP:"",
        INSURE_CO:"",
        INSURE_TYP:"",
        UPD_ACN_CONTRACT_NUM:"",
        CONTRACT_TYP_LIST:[

            {
            CD: "CM",
            NM: "CM"
            },
            {
            CD: "TM",
            NM: "TM",
            },
            {
            CD: "OFF",
            NM: "오프",
            },            
            {
            CD: "PUBLIC",
            NM: "공동"
            },
            {
            CD: "SHORTTERM",
            NM: "단기",
            },
            {
            CD: "DCM",
            NM: "CM타점"
            },
            {
            CD: "DTM",
            NM: "TM타점",
            },                
        ],
        INSURE_CO_LIST: [
        
            {
            CD: "HD",
            NM: "현대"
            },
            {
            CD: "DB",
            NM: "DB",
            },
            {
            CD: "KB",
            NM: "KB"
            },
            {
            CD: "HH",
            NM: "한화",
            },
            {
            CD: "HK",
            NM: "흥국"
            },
            {
            CD: "AXA",
            NM: "AXA",
            },              
            {
            CD: "CR",
            NM: "캐롯"
            },
            {
            CD: "HN",
            NM: "하나",
            },
            {
            CD: "MZ",
            NM: "메리츠"
            },
            {
            CD: "SS",
            NM: "삼성",
            },
            {
            CD: "LT",
            NM: "롯데"
            },
            {
            CD: "MG",
            NM: "MG",
            },           
        ],      

        INSURE_TYP_LIST: [
      
            {
            CD: "INDIVIDUAL",
            NM: "개인용"
            },
            {
            CD: "WORK",
            NM: "업무용",
            },
            {
            CD: "SALES",
            NM: "영업용"
            },
            {
            CD: "BIKE",
            NM: "이륜차",
            },
        ],      

        PERFORMENCE_TYP_LIST: [
            {
            CD: "1",
            NM: "전보험사 동일",
            },        
            {
            CD: "2",
            NM: "공동"
            },
            {
            CD: "3",
            NM: "단기",
            },
            {
            CD: "4",
            NM: "성과수수료부지급",
            },        
            {
            CD: "5",
            NM: "해지"
            },
            {
            CD: "6",
            NM: "해당없음",
            },    
            {
            CD: "7",
            NM: "월말정산",
            },       
            {
            CD: "8",
            NM: "얼마감지급",
            },                             
        ],    
        CONTRACT_STATUS_LIST: [
  
            {
            CD: "NORMAL",
            NM: "정상"
            },
            {
            CD: "TERMINATION",
            NM: "해지",
            },
        ],          
        RENEWAL_STATUS_LIST: [
  
            {
            CD: "COMPLETE",
            NM: "가입"
            },
            {
            CD: "UNCOMPLETE",
            NM: "미가입"
            },
            {
            CD: "UNESTIMATE",
            NM: "미견적",
            },
            {
            CD: "ESTIMATE",
            NM: "견적",
            },                        
        ],      
        RENEWAL_STATUS:"",
        CONTRACT_STATUS:"",
        PERFORMENCE_TYP:"",
        USER_BK:"",
        USER_ACT:"",
        INSURE_FEE:"",
        AD_FEE:"",
        CEN_TYPE_CD:"",
        CAR_NM:"",
        CAR_YEAR:"",
        CAR_NUM:"",
        SIGNIFICANT:"",
        MEMO:"",





        gridDataHeaders: [
          { text: "No",       value: "index",   width: "50px",  align: "center", sortable: false,},
          { text: "파일명",    value: "ORIGINAL_FILENAME",  sortable: false,},
          { text: "크기",      value: "FILE_SIZE", width: "80px", align: "center", sortable: false,},
          { text: "다운로드",   value: "DOWNLOAD", width: "70px", align: "center", sortable: false,},
          { text: "다운로드수", value: "DNLOD_CNT", width: "80px", align: "center", sortable: false,},
        ],
        gridDataText    : [], //첨부파일 리스트
        gridListSelected: [], //파일업로드 팝업 변수











            nowNum: "",
            CHK_BL: false,
            template: true,
            
            isLoading: false,
            
            senderList: [],

            searchValue_dataTable1: {
                CHNL_KIND: "SMS",
                TITLE: null,
                USE_YN: "Y"
            },

            inquiredCustomerNO: null,
            selectedSender: "",

            dataTable1Options: {
                fixedHeader: true,
                headers: [
                    { text: "순번", value: "ROWNUM", align: "center", width: "50px" },
                    { text: "제목", value: "TITLE" },
                ],
                height: "690px",
                hideDefaultFooter: true,
                itemKey: "ID",
                itemsPerPage: 200,
                noDataText: "조회된 결과가 없습니다.",
                page: 1,
                singleSelect: true,

                load: this.getList_dataTable1,
            },
            paginationOptions_dataTable1: {
                totalVisible: 10
            },

            dataTable2Options: {
                disablePagination: true,
                fixedHeader: true,
                headers: [
                    { text: "순번", value: "index", align: "center", width: "50px" },
                    { text: "고객번호", value: "CUST_NO", align: "center", width: "100px" },
                    { text: "고객명", value: "CUST_NM",  align: "center", width: "100px" },
                    { text: "전화번호", value: "CUST_CELLPHN_NO", align: "center" },
                ],
                height: "116px",
                hideDefaultFooter: true,
                itemKey: "index",
                items: [],
                itemsPerPage: 50,
                noDataText: "발송고객이 없습니다.",
                page: 1,
                singleSelect: true
            },

            requestData: {
                headers: {
                    SERVICE: "message.template",
                    TYPE: "BIZ_SERVICE",
                },
                sendData: {},
            },
            requestData_dataTable1: {},
            requestData_dataTable2: {},

            selectedKey_dataTable1: null,
            selectedRows_dataTable1: [],
            selectedKey_dataTable2: null,
            selectedRows_dataTable2: [],

            customerInfo: {
                CUST_NO: null,
                CUST_NM: null,
                CUST_CELLPHN_NO: null,
                IsInquire: false
            },
            CUST_PHN_NO: "",

            smsTemplateDetail: {
                ID: null,
                TITLE: "",
                CONTENT: ""
            },
        };
    },
    filters: {
        inputNumberFormat(v){
            return String(v).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
    },
    computed: {        
        dataTable1Instance: function() {
            return this.$refs.dataTable1;
        },
        dataTable2Instance: function() {
            return this.$refs.dataTable2;
        },
        titleLen: function() {
            return iconv.encode(he.decode(this.smsTemplateDetail.TITLE), "euc-kr").length;
        },
        contentLen: function() {
            let pleng =  iconv.encode(he.decode(this.smsTemplateDetail.CONTENT), "euc-kr").length;

            let frmLeng = pleng.toString();
            frmLeng = frmLeng.replace(/(\d{3})(\d{3})(\d{3})/, '$1,$2,$3');

            return frmLeng;
        }
    },
    methods: {
        init: async function() {
            // 헤더 초기화 및 세팅
            this.setDataTableParams_dataTable1();

            let commonCodeItems = await this.mixin_common_code_get_all([ "TWB100" ]);

            this.senderList = this.mixin_common_code_get(commonCodeItems, "TWB100");

            // this.selectedSender = '01'
            this.selectedSender = this.senderList[0].value
            this.nowNum = _.find(this.senderList, { 'value': this.selectedSender }).info4
            console.log(" this.nowNum init  ",this.nowNum)
            console.log(" this.senderList init  ",this.senderList)

        },

        setDataTableParams_dataTable1: function() {
            this.requestData_dataTable1 = _.cloneDeep(this.requestData);
            // header 세팅
            this.requestData_dataTable1.headers["URL"] = "/api/message/template/list";
            this.requestData_dataTable1.headers["METHOD"] = "list";
            this.requestData_dataTable1.headers["ASYNC"] = false;

            // sendData 세팅
            this.requestData_dataTable1.sendData = this.searchValue_dataTable1;
            // this.requestData_dataTable1.sendData["USER_ID"] = this.$store.getters["userStore/GE_USER_ROLE"].userId;
        },
        changeNum(selectedSender){
            console.log(" this.senderList  ",this.senderList)
            console.log(" this.selectedSender  ",this.selectedSender)
            console.log(" this.selectedSender.value  ",this.selectedSender.value)

            this.nowNum = _.find(this.senderList, { 'value': this.selectedSender }).info4
            console.log(" this.nowNum  ",this.nowNum)
        },
        getList_dataTable1: async function(loadOptions) {
            // 페이징 정보 세팅
            this.requestData_dataTable1.headers["ROW_CNT"] = loadOptions.itemsPerPage;
            this.requestData_dataTable1.headers["PAGES_CNT"] = loadOptions.page;
            if (loadOptions.sortBy && loadOptions.sortBy.length > 0)
                this.requestData_dataTable1.sendData["SORT_ORDR"] = loadOptions.sortBy[0];
            else this.requestData_dataTable1.sendData["SORT_ORDR"] = undefined;
            if (loadOptions.sortDesc && loadOptions.sortDesc.length > 0)
                this.requestData_dataTable1.sendData["SORT_DRCT"] = loadOptions.sortDesc[0] ? "desc" : "asc";
            else this.requestData_dataTable1.sendData["SORT_DRCT"] = undefined;

            return this.common_postCall(this.requestData_dataTable1).then((response) => {
                if (this.mixin_isEmpty(response) || response.HEADER.ERROR_FLAG) {
                    this.common_alert("시스템에 오류가 발생하였습니다.", "error");
                    return false;
                }

                let header = response.HEADER;
                let data = response.DATA;
                _.each(data, (item) => {
                    item.TITLE = this.restoreXSS(item.TITLE);
                    // 화면에서는 줄바꿈 문자를 \n으로 처리하지만, 서버에서는 \r\n으로 처리하므로 서버에서 가져온 문자열의 줄바꿈 문자를 모두 변경
                    item.CONTENT = _.replace(item.CONTENT, /\r\n/gi, "\n");
                    item.CONTENT = this.restoreXSS(item.CONTENT);
                    item.REG_DTTM = item.REG_DTTM.substr(0, 19);
                    item.UPD_DTTM = item.UPD_DTTM.substr(0, 19);
                });

                return {
                    data: data,
                    totalCount: header.TOT_COUNT,
                };
            });
        },
        search_dataTable1: function() {
            this.setDataTableParams_dataTable1();

            if (this.dataTable1Options.page > 1) this.dataTable1Options.page = 1;
            else this.dataTable1Instance.loadData();
        },
        checkDisableAddRowBtn: function() {
            if ((!_.isEmpty(this.customerInfo.CUST_NO) && this.customerInfo.CUST_NO != "00000000" && !this.customerInfo.IsInquire) || this.checkExistCust()){
                return true;
            }
            return false;
        },
        checkExistCust: function() {
            let instance = this.$refs.dataTable2;
            let data = instance ? instance.getData() : null;
            if (data && this.customerInfo && _.find(data, { CUST_NO: (this.customerInfo.CUST_NO || "99999999"), CUST_NM: this.customerInfo.CUST_NM, CUST_CELLPHN_NO: this.customerInfo.CUST_CELLPHN_NO })){
                return true;
            }
            return false;
        },
        onInputCustNo: function(e) {
            if (this.inquiredCustomerNO != e) {
                this.customerInfo.CUST_NM = null;
                this.customerInfo.CUST_CELLPHN_NO = null;
                this.customerInfo.IsInquire = false;
            }
        },
        getCustomerInfo: function(addRow) {
            if (!this.customerInfo.CUST_NO || this.customerInfo.CUST_NO.length < 8) {
                this.common_alert("고객번호는 8자리 이상 입력해야 합니다.", "noti");
                return false;
            }

            let requestData = {
                headers: {
                    SERVICE: "message.channel",
                    TYPE: "BIZ_SERVICE",
                },
                sendData: {},
            };

            // header 세팅
            requestData.headers["URL"] = "/api/message/channel/customerinfo/inquire";
            requestData.headers["METHOD"] = "inquire";
            requestData.headers["ASYNC"] = false;

            // sendData 세팅
            requestData.sendData.SRCH_KEY = this.customerInfo.CUST_NO;
            requestData.sendData.QryTpCode = "0"; // 고객번호로 조회 시 "0"

            this.common_postCall(requestData).then((response) => {
                if (this.mixin_isEmpty(response) || response.HEADER.ERROR_FLAG) {
                    let errorMessages = response.HEADER.ERROR_MSG ? response.HEADER.ERROR_MSG.split("\\n") : null;
                    let errorMsg = _.find(errorMessages, msg => {
                        return _.includes(msg, "에러 메시지: ");
                    });
                    let msg = errorMsg ? _.trim(errorMsg.split(":")[1]) : "시스템에 오류가 발생하였습니다."
                    this.common_alert(msg, "error");
                    return false;
                }

                let header = response.HEADER;
                let data = response.DATA;
                
                // this.customerInfo.CUST_NO = data[0].ClntNo;
                this.customerInfo.CUST_NM = data[0].ClntNm;
                this.customerInfo.CUST_CELLPHN_NO = (data[0].ClntHpCode ? data[0].ClntHpCode + "-" : "") + (data[0].ClntHpExno ? data[0].ClntHpExno + "-" : "") + data[0].ClntHpSeqno;
                this.CUST_PHN_NO = (data[0].ClntHpCode ? data[0].ClntHpCode + "-" : "") + (data[0].ClntHpExno ? data[0].ClntHpExno + "-" : "") + data[0].ClntHpSeqno;
                this.customerInfo.IsInquire = true;
                this.inquiredCustomerNO = data[0].ClntNo;

                if (addRow === true) this.addRow();
            });
        },
        reset: function() {
            // 선택된 열 초기화
            this.selectedKey_dataTable2 = null;
            this.selectedRows_dataTable2 = [];
            // 상세 정보 초기화
            this.customerInfo = {
                CUST_NO: null,
                CUST_NM: null,
                CUST_CELLPHN_NO: null,
                IsInquire: false
            };
        },
        addRow: function() {
            let totalCount = this.dataTable2Instance.getTotalCount(); // 현재 Grid의 전체 데이터 수 가져오기
            if (totalCount >= 10) {
                this.common_alert("발송고객은 최대 10명까지만 추가 가능합니다.", "noti");
            }
            else if (!_.isEmpty(this.customerInfo.CUST_NO) && this.customerInfo.CUST_NO.length != 8 && this.CHK_BL == false) {
                this.common_alert("고객번호는 8자리로 입력해야 합니다.", "noti");
            }
            else if (!this.customerInfo.CUST_NM && this.CHK_BL == false) {
                this.common_alert("고객명을 입력해야 합니다.", "noti");
            }
            else if (this.customerInfo.CUST_CELLPHN_NO == null) {
                this.common_alert("휴대전화번호를 입력해야 합니다.", "noti");
            }
            else if (!/^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}/.test(this.customerInfo.CUST_CELLPHN_NO)) {
                this.common_alert("잘못된 휴대전화번호입니다.", "noti");
            }
            else {
                let row = _.cloneDeep(this.customerInfo);
                if (_.isEmpty(row.CUST_NO)){
                    row.CUST_NO = "00000000";
                    row.CUST_NM = "미인증고객";
                } 
                row.index = totalCount + 1;
                this.dataTable2Instance.addRow(row); // row 추가
                this.selectedKey_dataTable2 = row.index;
                if (this.selectedRows_dataTable2.length > 0) this.selectedRows_dataTable2.splice(0, 1, row);
                else this.selectedRows_dataTable2.push(row);
            }
        },
        delete: function() {
            this.common_confirm('삭제하시겠습니까?', this.deleteRow, null, null, null, 'chk');
        },
        deleteRow: function() {
            let index = _.findIndex(this.dataTable2Instance.getData(), { CUST_NO: (this.customerInfo.CUST_NO || "99999999"), CUST_NM: this.customerInfo.CUST_NM, CUST_CELLPHN_NO: this.customerInfo.CUST_CELLPHN_NO });
            if (index >= 0) {
                this.dataTable2Instance.deleteRow(index); // row 삭제
                this.$nextTick(() => {
                    let items = this.dataTable2Instance.getData();
                    for (let i = index; i < items.length; i++) {
                        this.dataTable2Instance.updateRow(i, "index", i + 1); // row 업데이트
                        // items[i].index = i + 1;
                    }
                });
            }
            
            this.selectedKey_dataTable2 = null;
            this.selectedRows_dataTable2 = [];
            this.customerInfo = {
                CUST_NO: null,
                CUST_NM: null,
                CUST_CELLPHN_NO: null,
                IsInquire: false
            };
        },
        send: function() {
            let totalCount = this.dataTable2Instance.getTotalCount();
            // if (totalCount == 0 && this.CHK_BL == false) {
            if (totalCount == 0) {
                this.common_alert("발송 대상이 존재하지 않습니다.", "noti");
            }
            else if (!this.smsTemplateDetail.TITLE) {
                this.common_alert("제목을 입력해야 합니다.", "noti");
            }
            else if (this.titleLen > 150) {
                this.common_alert(`제목은 150Byte를 초과할 수 없습니다.\n현재 ${this.titleLen}Byte 입력하셨습니다.`, "noti");
            }
            else if (!this.smsTemplateDetail.CONTENT) {
                this.common_alert("내용을 입력해야 합니다.", "noti");
            }
            else if (this.contentLen > 4000) {
                this.common_alert(`내용은 4,000Byte를 초과할 수 없습니다.\n현재 ${this.contentLen}Byte 입력하셨습니다.`, "noti");
            }
            // else if (!this.selectedSender) {
            //     this.common_alert("보내는 사람을 선택해야 합니다.", "noti");
            // }
            else {
                this.common_confirm('발송하시겠습니까?', this.sendSMS, null, null, null, 'chk');
            }
        },
        sendSMS: function() {
            console.log("CUST_CELLPHN_NO-->",this.customerInfo.CUST_CELLPHN_NO);
            this.isLoading = true;
            console.log("this.selectedSender sms ",this.selectedSender)
            console.log("this.senderList sms ",this.senderList)
            let rtnPhnNo = _.find(this.senderList, { 'value': this.selectedSender }).info4
            // let rtnPhnNo = _.find(this.senderList, { value: this.selectedSender }).info4;
            if (!rtnPhnNo) {
                this.common_alert("회신번호가 존재하지 않습니다.\n공통코드에 등록 후 다시 시도해 주세요.", "error");
                this.isLoading = false;
                return;
            }

            let requestData = {
                headers: {
                    SERVICE: "message.sms",
                    TYPE: "BIZ_SERVICE",
                },
                sendData: {},
            };
            
            // header 세팅
            requestData.headers["URL"] = "/api/message/sms/send";
            requestData.headers["METHOD"] = "send";
            requestData.headers["ASYNC"] = false;

            // sendData 세팅
            if(this.CHK_BL == true){
                requestData.sendData.CUST_CELLPHN_NO = this.customerInfo.CUST_CELLPHN_NO;
            }
            requestData.sendData.USER_ID = this.$store.getters["userStore/GE_USER_ROLE"].userId;
            requestData.sendData.USER_NM = this.$store.getters["userStore/GE_USER_ROLE"].userName;
            requestData.sendData.USER_ATTR_A = this.$store.getters["userStore/GE_USER_ROLE"].USER_ATTR_A;
            requestData.sendData.ATTR_DIV_NM_A = this.$store.getters["userStore/GE_USER_ROLE"].ATTR_DIV_NM_A;
            requestData.sendData.RTN_PHN_NO = rtnPhnNo; // 회신번호
            requestData.sendData.TITLE = this.smsTemplateDetail.TITLE;
            requestData.sendData.CONTENT = this.smsTemplateDetail.CONTENT;
            requestData.sendData.RECP_LIST = _.map(this.dataTable2Instance.getData(), item => {
                let newItem = {};
                _.each(Object.keys(item), key => {
                    if (key != "IsInquire") newItem[key] = item[key];
                });

                return newItem;
            }) ;
            
            this.common_postCall(requestData).then((response) => {
                if (this.mixin_isEmpty(response) || response.HEADER.ERROR_FLAG) {
                    let errorMessages = response.HEADER.ERROR_MSG ? response.HEADER.ERROR_MSG.split("\n") : null;
                    let errorMsg = _.find(errorMessages, msg => {
                        return _.includes(msg, "에러 메시지: ");
                    });
                    let msg = errorMsg ? _.trim(errorMsg.split(":")[1]) : "시스템에 오류가 발생하였습니다."
                    this.common_alert(msg, "error");
                    // this.isLoading = false;
                    return false;
                }

                let header = response.HEADER;
                let data = response.DATA;
                
                this.common_alert("정상 처리되었습니다.", "done");
                // this.isLoading = false;
            }).finally(() => {
                this.isLoading = false;
            });
        },
        keyUpTelInp(val) {
            let numPttn = /[^0-9]/g;
            val = val.replace(numPttn, "");
            if(val.length > 8)  {
                val = this.mixin_newPhoneFormat(val);
            }
            this.customerInfo.CUST_CELLPHN_NO = val;
            this.CUST_PHN_NO = val;
        },
        onClickRow_dataTable1: function(item, row) { 
            if (this.selectedKey_dataTable1 === item.ID) {
                row.select(false);
                this.selectedKey_dataTable1 = null;
                this.smsTemplateDetail = {
                    ID: null,
                    TITLE: "",
                    CONTENT: ""
                };
            } else {
                row.select(true);
                this.selectedKey_dataTable1 = item.ID;
                this.smsTemplateDetail = Object.assign(this.smsTemplateDetail, item);
            }
        },
        onClickRow_dataTable2: function(item, row) { 
            if (this.selectedKey_dataTable2 === item.index) {
                row.select(false);
                this.selectedKey_dataTable2 = null;
                this.customerInfo = {
                    CUST_NO: null,
                    CUST_NM: null,
                    CUST_CELLPHN_NO: null,
                    IsInquire: false
                };
            } else {
                row.select(true);
                this.selectedKey_dataTable2 = item.index;
                this.customerInfo = Object.assign(this.customerInfo, item);
            }
        },

        setCustomer: function(cust_no) {
            this.customerInfo.CUST_NO = cust_no;
            this.getCustomerInfo(true);
        },
        noCertMember(){
            if(this.CHK_BL == true){
                this.customerInfo.CUST_NO = "00000000"
                this.customerInfo.CUST_NM = "미인증고객"
            }else{
                this.customerInfo.CUST_NO = ""
                this.customerInfo.CUST_NM = ""
            }
            
        },

        openE030102P01() {
            this.$openWindow('E030102P01','E030102P01','1300','895');
        },





























    calculateAd(){
        
        this.INSURE_FEE = this.INSURE_FEE.replaceAll(",", "");

        if(this.INSURE_TYP == "BIKE" || this.INSURE_TYP == "SALES"){
            this.AD_FEE = 0
        }
        else if(this.CONTRACT_TYP == "CM" || this.CONTRACT_TYP == "TM"){
            // this.AD_FEE = Math.ceil(this.INSURE_FEE * 0.04835) 
            this.AD_FEE = Math.round((this.INSURE_FEE * 0.04835)/10) * 10 
        }else if(this.CONTRACT_TYP == "OFF"){
            this.AD_FEE = Math.round((this.INSURE_FEE * 0.0967)/10) * 10
        }else{
            this.AD_FEE = 0
        }

        if(this.PERFORMENCE_TYP == 1 ||
        this.PERFORMENCE_TYP == 2 || this.PERFORMENCE_TYP == 3 ||
        this.PERFORMENCE_TYP == 4 || this.PERFORMENCE_TYP == 5 ||
        this.PERFORMENCE_TYP == 7 || this.PERFORMENCE_TYP == 8){
            this.AD_FEE = 0
        }
            
        this.INSURE_FEE =Number(this.INSURE_FEE).toLocaleString('ko-KR');
        this.AD_FEE = Number(this.AD_FEE).toLocaleString('ko-KR');            
        
        //  Math.round(121.123) / 10) * 10;

    },


    changeDate(type) {
      // if(
      //   moment(this.dates[0]).isBefore(moment(new Date()).subtract(30, 'days').format('YYYY-MM-DD')) ||
      //   moment(this.dates[1]).isAfter(moment(new Date()).subtract(0, 'days').format('YYYY-MM-DD'))
      // ){
      //   this.common_alert('검색 기간은 최근 1개월까지 선택 가능합니다.');
      //   const date = moment().format("YYYY-MM-DD");
      //   this.dates[0] = date;
      //   this.dates[1] = date;
      //   this.from = date;
      //   this.to = date;
      //   return;
      // }

      if(type == 'start') {
        // if (moment(this.dates[0]).isBefore(moment(this.dates[1]).subtract(30, 'days').format('YYYY-MM-DD'))) {
        //   this.common_alert('검색 기간은 최대 1개월까지 선택 가능합니다.');
        //   const date = moment().format("YYYY-MM-DD");
        //   this.dates[0] = date;
        //   this.dates[1] = date;
        //   this.from = date;
        //   this.to = date;
        //   return;
        // } else {
          this.from = this.dates[0];
          var abc = parseInt(this.dates[0].substr(0,4)) + 1
          this.dates[1] = abc.toString() + this.dates[0].substr(4)

          console.log("abc abc abc :  ", this.dates[0].substr(4) )
        // }
      } else if (type == 'end') {
        // if (moment(this.dates[1]).isAfter(moment(this.dates[0]).add(30, 'days').format('YYYY-MM-DD'))) {
        //   this.common_alert('검색 기간은 최대 1개월까지 선택 가능합니다.');
        //   const date = moment().format("YYYY-MM-DD");
        //   this.dates[0] = date;
        //   this.dates[1] = date;
        //   this.from = date;
        //   this.to = date;
        //   return;
        // } else {
          this.to = this.dates[1];
        // }
      }
    },


    newSave(){
        if(this.CONTRACT_NUM == "" || this.CONTRACT_NUM == undefined){
            this.common_alert("계약번호를 입력해주세요", "error");
        }else if(this.USER_ID == "" || this.USER_ID == undefined){
            this.common_alert("광고인를 입력해주세요", "error");
        }else if(this.INSURE_CO == "" || this.INSURE_CO == undefined){
            this.common_alert("보험사를 입력해주세요", "error");
        }else{
            this.common_confirm('저장 하시겠습니까?', this.clickBtnSave, null, null, null, 'chk');

        }
    },
    
    
     async clickBtnSave() {
            // this.selectedRow = undefined;
            // if (this.selectedRow.length > 0) {
            //     this.selectedRow.forEach(item => {
            //         item.select(false);
            //     });
            // }

            let requestData = {
                headers: {
                    // SERVICE: "easydirect.popup.detail",
                    METHOD: "",
                    TYPE: 'BIZ_SERVICE',
                    ROW_CNT: "200",
                    PAGES_CNT: "1",                    
                },
                sendData: {}
            };

            if(this.MODE == "insert" || this.MODE == "renewal"){
                requestData.headers["SERVICE"] = "easydirect.popup.insert";
                requestData.headers["URL"] = "/api/easydirect/popup/insert";
                requestData.headers["METHOD"] = "insert";
                requestData.headers["ASYNC"] = false;                
            }else{
                requestData.headers["SERVICE"] = "easydirect.popup.update";
                requestData.headers["URL"] = "/api/easydirect/popup/update";
                requestData.headers["METHOD"] = "update";
                requestData.headers["ASYNC"] = false;                  
            }


            requestData.sendData["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY;
            //requestData.sendData["ASP_NEWCUST_KEY_DE"] = this.ASP_NEWCUST_KEY;


            this.INSURED_SSN = this.INSURED_SSN.replaceAll("-", "")
            this.CONTRACT_SSN = this.CONTRACT_SSN.replaceAll("-", "")
            this.INSURED_SSN = this.encryptAES_ECB(this.INSURED_SSN.replaceAll(" ", ""), process.env.VUE_APP_SSN_KEY);
            this.CONTRACT_SSN = this.encryptAES_ECB(this.CONTRACT_SSN.replaceAll(" ", ""), process.env.VUE_APP_SSN_KEY);

            requestData.sendData["INSURED_SSN"] = this.INSURED_SSN; //등록자

            requestData.sendData["SSN_KEY"] = process.env.VUE_APP_SSN_KEY; //등록자

            if(this.fixedFlag == true){
                requestData.sendData["CONTRACT_SSN"] = this.INSURED_SSN; //등록자
                requestData.sendData["CONTRACTOR"] = this.INSURED_PERSON; //등록자
                requestData.sendData["CONTRACT_HP"] = this.INSURED_HP.replaceAll("-", "");  //등록자
            }else{
                requestData.sendData["CONTRACT_SSN"] = this.CONTRACT_SSN; //등록자
                requestData.sendData["CONTRACTOR"] = this.CONTRACTOR; //등록자
                requestData.sendData["CONTRACT_HP"] = this.CONTRACT_HP.replaceAll("-", ""); //등록자

            }




            requestData.sendData["USER_ID"] = this.USER_ID;
            requestData.sendData["COUNSELOR_ID"] = this.COUNSELOR_ID; //등록자
            requestData.sendData["INSURED_PERSON"] = this.INSURED_PERSON; //등록자

            requestData.sendData["INSURED_HP"] = this.INSURED_HP.replaceAll("-", ""); //등록자
            
            requestData.sendData["CONTRACT_TYP"] = this.CONTRACT_TYP; //등록자
            requestData.sendData["INSURE_CO"] = this.INSURE_CO; //등록자
            requestData.sendData["INSURE_TYP"] = this.INSURE_TYP; //등록자

            requestData.sendData["CONTRACT_NUM"] = this.CONTRACT_NUM; //등록자
            requestData.sendData["CONTRACT_STATUS"] = this.CONTRACT_STATUS; //등록자
            requestData.sendData["PERFORMENCE_TYP"] = this.PERFORMENCE_TYP; //등록자

            console.log(this.INSURE_FEE)
            requestData.sendData["INSURE_FEE"] = this.INSURE_FEE.replaceAll(",", ""); //등록자
            requestData.sendData["AD_FEE"] = this.AD_FEE.replaceAll(",", ""); //등록자
            requestData.sendData["CAR_NM"] = this.CAR_NM; //등록자
            requestData.sendData["CAR_YEAR"] = this.CAR_YEAR; //등록자
            requestData.sendData["CAR_NUM"] = this.CAR_NUM; //등록자
            requestData.sendData["COUNSELOR_ID"] = this.COUNSELOR_ID; //등록자
            requestData.sendData["SIGNIFICANT"] = this.SIGNIFICANT; //등록자
            requestData.sendData["MEMO"] = this.MEMO; //등록자
            requestData.sendData["RENEWAL_STATUS"] = this.RENEWAL_STATUS; //등록자

            requestData.sendData["INIT_DTTM"] = this.init_dates[0];
            requestData.sendData["START_DTTM"] = this.dates[0];            
            requestData.sendData["END_DTTM"] = this.dates[1];            
            requestData.sendData["FILE"] = this.FILE_GROUP_KEY;            






            requestData.sendData["PROC_ID"] = this.PROC_ID; //처리자
            requestData.sendData["REGR_DEPT_CD"] = "x";
            requestData.sendData["REGR_ID"] =  this.$store.getters['userStore/GE_USER_ROLE'].userId; //등록자
            requestData.sendData["REGR_DTTM"] = "SYSDATE";
            requestData.sendData["UPD_DEPT_CD"] = "x";
            requestData.sendData["UPD_ID"] = this.$store.getters['userStore/GE_USER_ROLE'].userId; //등록자
            requestData.sendData["UPD_DTTM"] = "SYSDATE";            
            
            
            requestData.sendData["ORI_CONTRACT_NUM"] = this.UPD_ACN_CONTRACT_NUM;
            requestData.sendData["SSN_KEY"] = process.env.VUE_APP_SSN_KEY;            
            const response = await this.common_postCall(requestData);

            if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {

                this.INSURE_FEE = this.INSURE_FEE.replaceAll(",", "");
                if(this.CONTRACT_TYP == "CM" || this.CONTRACT_TYP == "TM"){
                    this.AD_FEE = Math.ceil(this.INSURE_FEE * 0.04835) 
                }else if(this.CONTRACT_TYP == "OFF"){
                    this.AD_FEE = Math.ceil(this.INSURE_FEE * 0.0967)
                }else{
                    this.AD_FEE = this.INSURE_FEE
                }
                    
                this.INSURE_FEE =Number(this.INSURE_FEE).toLocaleString('ko-KR');
                this.AD_FEE = Number(this.AD_FEE).toLocaleString('ko-KR');            
                
                
                
                let iconType = 'done';
                let msg = "정상처리 되었습니다.";



                //alert창 띄우기
                this.common_alert(msg, iconType);
                var ACN_USER_SSN = this.encryptAES_ECB(this.CONTRACT_NUM, process.env.VUE_APP_SSN_KEY);
                this.searchContract(ACN_USER_SSN) 
            }else{
                let msg = response.HEADER.ERROR_MSG;
                let iconType = 'error';

                this.common_alert("계약번호가 중복되었습니다", iconType);

            }

        }, 

        // async selectUserInfo(item){
        //                     @change="(event) => selectUserInfo(event.USER_NM)"


        //     console.log("item ", item)

        //     // this.USER_LIST[]
            
        // },
    
        async searchContract(ACN_CONTRACT_NUM) {
            // this.selectedRow = undefined;
            // if (this.selectedRow.length > 0) {
            //     this.selectedRow.forEach(item => {
            //         item.select(false);
            //     });
            // }

            let requestData = {
                headers: {
                    SERVICE: "easydirect.popup.detail",
                    METHOD: "",
                    TYPE: 'BIZ_SERVICE',
                    ROW_CNT: "200",
                    PAGES_CNT: "1",                    
                },
                sendData: {}
            };


            requestData.headers["URL"] = "/api/easydirect/popup/detail";
            requestData.headers["METHOD"] = "select";
            requestData.headers["ASYNC"] = false;
            // requestData.headers["ROW_CNT"] = loadOptions.itemsPerPage;
            // requestData.headers["PAGES_CNT"] = loadOptions.page; 

            requestData.sendData["CONTRACT_NUM"] = ACN_CONTRACT_NUM;
            requestData.sendData["SSN_KEY"] = process.env.VUE_APP_SSN_KEY;

            const response = await this.common_postCall(requestData);
            console.log("나야나 : ", response)

            if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {
                let header = response.HEADER;
                let data = response.DATA;


                this.USER_ID = response.DATA[0].USER_ID
                this.COUNSELOR_ID = response.DATA[0].COUNSELOR_ID
                this.INSURED_PERSON = response.DATA[0].INSURED_PERSON
                this.INSURED_HP = this.mixin_setPhoneNo(response.DATA[0].INSURED_HP)
                this.CONTRACTOR = response.DATA[0].CONTRACTOR
                

                let chkGroup = this.$store.getters["authStore/GE_AUTH_LOGIN_DATA"].AUTHOR_GROUP_NM_LIST;   
                if(chkGroup.indexOf("관리자") > -1 || chkGroup.indexOf("매니저") > -1/*chkGroup == "이베스트관리자" || chkGroup == "시스템관리자"*/){
                    this.INSURED_SSN = response.DATA[0].INSURED_SSN.substr(0, 6).concat("-").concat(response.DATA[0].INSURED_SSN.substr(6))
                    this.CONTRACT_SSN = response.DATA[0].CONTRACT_SSN.substr(0, 6).concat("-").concat(response.DATA[0].CONTRACT_SSN.substr(6))
                    this.USER_ACT = response.DATA[0].USER_ACT
                }else{
                    this.CONTRACT_SSN = response.DATA[0].CONTRACT_SSN.substr(0, 6).concat("-").concat(response.DATA[0].CONTRACT_SSN.substr(6, 1)).concat("******");
                    this.INSURED_SSN = response.DATA[0].INSURED_SSN.substr(0, 6).concat("-").concat(response.DATA[0].INSURED_SSN.substr(6, 1)).concat("******");
                    this.USER_ACT = response.DATA[0].USER_ACT.substr(0, 6).concat("******");
                    

                }                 

                if(this.MODE == "update"){

                    this.CONTRACT_NUM = response.DATA[0].CONTRACT_NUM
                    this.INSURE_FEE =Number(response.DATA[0].INSURE_FEE).toLocaleString('ko-KR');
                    this.AD_FEE = Number(response.DATA[0].AD_FEE).toLocaleString('ko-KR');

                    this.dates[0] = response.DATA[0].START_DTTM.substr(0, 10);                
                    this.dates[1] = response.DATA[0].END_DTTM.substr(0, 10);                
                    this.init_dates[0] = response.DATA[0].INIT_DTTM.substr(0, 10);   
                }else if(this.MODE == "renewal"){
                    this.CONTRACT_NUM = ""
                    this.INSURE_FEE = "0"
                    this.AD_FEE = "0"

                    this.dates[0] = response.DATA[0].END_DTTM.substr(0, 10);           
                    // this.dates[1] = response.DATA[0].END_DTTM.substr(0, 10);
                    var numTemp = Number(response.DATA[0].END_DTTM.substr(0, 4)) + 1;

                    console.log("numTemp  ", numTemp)
                    this.dates[1] = numTemp.toString().concat(response.DATA[0].END_DTTM.substr(4, 6))

                    this.init_dates[0] = response.DATA[0].END_DTTM.substr(0, 10);      
                    // new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                    //                 .toISOString()
                    //                 .substr(0, 10)  
                }


                this.CONTRACT_HP = this.mixin_setPhoneNo(response.DATA[0].CONTRACT_HP)
                this.CONTRACT_TYP = response.DATA[0].CONTRACT_TYP
                this.INSURE_CO = response.DATA[0].INSURE_CO
                this.INSURE_TYP = response.DATA[0].INSURE_TYP
                this.PERFORMENCE_TYP = response.DATA[0].PERFORMENCE_TYP


                this.CONTRACT_STATUS = response.DATA[0].CONTRACT_STATUS
                this.USER_BK = response.DATA[0].USER_BK
                this.USER_ACT = response.DATA[0].USER_ACT


                this.CEN_TYPE_CD = response.DATA[0].CEN_TYPE_CD
                this.CAR_NM = response.DATA[0].CAR_NM
                this.CAR_YEAR = response.DATA[0].CAR_YEAR
                this.CAR_NUM = response.DATA[0].CAR_NUM
                this.SIGNIFICANT = response.DATA[0].SIGNIFICANT
                this.MEMO = response.DATA[0].MEMO
                this.RENEWAL_STATUS = response.DATA[0].RENEWAL_STATUS
                this.FILE = response.DATA[0].FILE
                           

                this.selectFile(this.FILE)

             
            }            
        },        

      setFileList(listData) {
        this.gridDataText = [];
  
        for(let i in listData) {
          let tempItem = listData[i];
          tempItem["FILE_SIZE"] = this.mixin_getFileSize(listData[i].FILE_SIZE);
          tempItem["index"] = ++i;
  
          this.gridDataText.push(tempItem);
        }
      },        
      async fileDownload(item){
        let busiType = "bbs";   //palette, phone, chat, km, bbs
        let pathType = "files"; //publics,files,images,videos,inqrytypeimage
  
        await this.mixin_fileDownload(item, busiType, pathType)
      },      
      clickDelFiles(){
        console.log(this.gridListSelected);
        if(this.gridListSelected.length != 0) {
          this.common_confirm('선택한 파일을 삭제하시겠습니까?',
              this.confirmDelYes, null, this.confirmDelNo, null, 'error');
        }
      },      


      async confirmDelYes() {
        let rtnBln = await this.deleteFilesMulti(this.gridListSelected);

        if (rtnBln) {
          // 첨부파일 dataList 호출
          let resList = await this.mixin_getFileList(this.gridListSelected[0].FILE_GROUP_KEY);
          this.setFileList(resList)

          this.gridListSelected = [];
        }
      },

      clickAddFiles() {
        let busiType = "bbs";   //palette, phone, chat, km, bbs
        let pathType = "files"; //publics,files,images,videos,inqrytypeimage

        this.dropZoneParam = {
          //ASP_NEWCUST_KEY : this.ASP_NEWCUST_KEY,
          ASP_NEWCUST_KEY : "001",
          fileGroupKey : this.FILE_GROUP_KEY,
          pathType : pathType, //publics,files,images,videos,inqrytypeimage
          busiType : busiType, //palette, phone, chat, km, bbs
          acceptedFiles : ".png,.jpg,.gif,.jpeg,.doc,.docx,.pdf,.csv,.xls,.xlsx,.ppt,.pptx",
          userOptions :{
            parallelUploads: 10,
            //url : process.env.VUE_APP_API_URL + "api/file/"+ busiType +"/"+ pathType +"/uploads",
            //url : "http://local.hkpalette.com:6001/" + "api/file/"+ busiType +"/"+ pathType +"/uploads",
          }
        };
        this.showDialog('VueDropzone');
      },      
      // 첨부파일 팝업 업로드 후
      async uploadFileResponse(data){
        this.hideDialog('VueDropzone');
        this.FILE_GROUP_KEY = data[0].fileGroupKey;

        let resList = await this.mixin_getFileList(data[0].fileGroupKey);
        this.setFileList(resList)
      },      
      async selectFile(data){
        this.hideDialog('VueDropzone');
        this.FILE_GROUP_KEY = data;

        let resList = await this.mixin_getFileList(data);
        this.setFileList(resList)
      },      

      showDialog(menu) {
        this[`dialog${menu}`] = true;
      },
      hideDialog(menu) {
        this[`dialog${menu}`] = false;
      },

      popupClose() {
        this.$emit('hide')
      },
          
    },

    
    created() {},
    async mounted() {
        this.init();


        this.COUNSELOR_LIST = await this.mixin_get_user("20220826113735368TWBJfPKh");    
        this.USER_LIST = await this.mixin_get_user("20220826113539330TWBaSY4N");   
        console.log("this.USER_LIST", this.USER_LIST)
        console.log("this.COUNSELOR_ID", this.COUNSELOR_ID)

    let chkGroup = this.$store.getters["authStore/GE_AUTH_LOGIN_DATA"].AUTHOR_GROUP_NM_LIST;   
    if(chkGroup.indexOf("관리자") > -1 || chkGroup.indexOf("매니저") > -1/*chkGroup == "이베스트관리자" || chkGroup == "시스템관리자"*/){
        this.CHK_BL = false;
    }else{
        this.CHK_BL = true;
    }    
        var ACN_CONTRACT_NUM = this.$route.query.ctr      
        this.UPD_ACN_CONTRACT_NUM = ACN_CONTRACT_NUM
        this.MODE = this.$route.query.mode      
        console.log("ctrctr   ", ACN_CONTRACT_NUM)

        if(this.MODE == 'update' ||this.MODE == 'renewal'){
            this.searchContract(ACN_CONTRACT_NUM)
        }else{
            this.COUNSELOR_ID = this.$store.getters["userStore/GE_USER_ROLE"].userId;
            this.CONTRACT_STATUS = "NORMAL"

        }


    },
    watch: {}
};
</script>

<style></style>